<template>
  <div class="footBar">
    <div class="container">
      <div class="foot-left">
        <div class="foot-nav">
          
          <!-- <span class="nav-item" @click="gotoUs">关于我们</span> -->
          <!-- <span class="nav-item" @click="gotoBrand">品牌简介</span> -->
          <!-- <span class="nav-item" @click="gotoPartner">河伙人</span> -->
          <!-- <span class="nav-item" @click="gotoContact">联系我们</span>
          <span class="nav-item" @click="gotoNews">媒体报道</span>
          <span class="nav-item" @click="gotJoinUs">加入我们</span>
          <span class="nav-item" @click="gotoAggreement">用户协议</span> -->
        </div>
        <div class="copy-right" v-if="!isHxxBaby">
          <p>杭州河象网络科技有限公司</p>
          <p><a href="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/yyzz.png" target="_blank">营业执照</a></p>
          <p><a href='https://beian.miit.gov.cn' target="_blank">{{icpText}}</a></p>
          <p>ICP 浙B2-20181300</p>
          <p><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002014018">浙公网安备 33011002014018号</a></p>
        </div>
        <div class="copy-right" v-if="!isHxxBaby">
          <p><a target="_blank" href="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/cbwjyxkz.png?v=20221123">出版物经营许可证</a></p>
          <p><a target="_blank" href="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/zzdxywjyxkz.png">增值电信业务经营许可证 浙B2-20181300</a></p>
          <p><a target="_blank" href="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/wlwhjyxkz.png">网络文化经营许可证（浙网文（2019）2122-225号）</a></p>
        </div>
        <div class="copy-right" v-if="isHxxBaby">
          <p>杭州河小象网络科技有限公司</p>
          <p><a href="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/hxx-yyzz.png" target="_blank">营业执照</a></p>
          <p><a href='https://beian.miit.gov.cn' target="_blank">{{icpText}}</a></p>
          <p><span @click.stop="routerTo('aboutHxx')">公司介绍</span></p>
          <p>ICP 浙B2-20181300</p>
        </div>        
      </div>
      <div class="foot-right" v-if="!isHxxBaby">
        <div class="wx">微信公众号</div>
        <div class="code">
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/wxapp_code.jpg">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "footBar",
  data() {
    return {};
  },
  computed: {
    icpText: function() {
      let txt = '浙ICP备17028580号-1';
      let h = window.location.host;
      h.match(/hexiaoxiang.com/) && (txt = '浙ICP备17028580号-1');
      h.match(/hexiaoxiang.com.cn/) && (txt = '浙ICP备17028580号-2');
      h.match(/hxxbaby.com/) && (txt = '浙ICP备17028580号-2');
      h.match(/aaao.ren/) && (txt = '浙ICP备17028580号-3');
      h.match(/aaaj.fit/) && (txt = '浙ICP备17028580号-4');
      h.match(/aaao.biz/) && (txt = '浙ICP备17028580号-6');
      return txt;
    },
    isHxxBaby: function() {
      let host = window.location.host;
      return host.match('hxxbaby.com');
    }
  },
  methods: {
    //路由跳转
    routerTo(path) {
      // let newPath = '/m/' + path;
      let newPath = path;
      this.$router.push({path: newPath});
    },
    gotoUs() {
      this.$router.push({ name: "aboutUs" });
      this.$emit("underline");
    },
    gotoContact(){
      this.$router.push({ name: "contactUs" });
      this.$emit("underline");
    },
    gotoAggreement(){
      this.$router.push({ name: "aggreement" });
      this.$emit("underline");
    },
    gotJoinUs(){
      this.$router.push({ name: "joinUs" });
      this.$emit("underline");
    },
    gotoPartner () {
      this.$router.push({ name: "partner" });
      this.$emit("underline");
    },
    gotoBrand(){
       this.$router.push({ name: "brand" });
      this.$emit("underline");
    },
    gotoNews(){
       this.$router.push({ name: "news" });
      this.$emit("underline");
    }
  }
};
</script>
<style scoped>
.footBar{
  width:100%;
  height:240px;
  box-sizing: border-box;
  padding: 30px 0;
  background:rgba(56,59,72,1);
  display: flex;
  justify-content: center;
  color: #fff;
}
.container{
  height: 100%;
  width: 1120px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.foot-left{
  display: flex;
  flex-wrap: wrap;
}
.foot-nav{
  width: 100%;
  justify-content: space-between;
  font-size:16px;
  font-family:MicrosoftYaHei;
  font-weight:400;
  line-height:36px;
  margin-top: 30px;
  text-align: left;
}
.nav-item{
  display: inline-block;
  margin-right: 38px;
  cursor: pointer;
}
.nav-item:hover{
  border-bottom: 1px solid #fff;
}
.nav-item:last-child{
  margin: 0;
}
.copy-right{
  font-size:13px;
  font-family:MicrosoftYaHei;
  font-weight:400;
  color:rgba(136,136,136,1);
  line-height:12px;
  padding: 10px 0;
  text-align: left;
  display: flex;
}
.copy-right p{
  color:#939393;
  margin-right: 15px;
}
.copy-right a {
  color: #939393;
}
.foot-right{
  width: 140px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.wx{
  margin-bottom: 14px;
  font-size:16px;
  display: block;
}
.code{
  width: 140px;
  height: 140px;
  background: rgba(255,255,255,1);
  border-radius: 8px;
}
.code img{
  width: 100%;
  height: 100%;
}
</style>
