var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footBar"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"foot-left"},[_c('div',{staticClass:"foot-nav"}),(!_vm.isHxxBaby)?_c('div',{staticClass:"copy-right"},[_c('p',[_vm._v("杭州河象网络科技有限公司")]),_vm._m(0),_c('p',[_c('a',{attrs:{"href":"https://beian.miit.gov.cn","target":"_blank"}},[_vm._v(_vm._s(_vm.icpText))])]),_c('p',[_vm._v("ICP 浙B2-20181300")]),_vm._m(1)]):_vm._e(),(!_vm.isHxxBaby)?_c('div',{staticClass:"copy-right"},[_vm._m(2),_vm._m(3),_vm._m(4)]):_vm._e(),(_vm.isHxxBaby)?_c('div',{staticClass:"copy-right"},[_c('p',[_vm._v("杭州河小象网络科技有限公司")]),_vm._m(5),_c('p',[_c('a',{attrs:{"href":"https://beian.miit.gov.cn","target":"_blank"}},[_vm._v(_vm._s(_vm.icpText))])]),_c('p',[_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.routerTo('aboutHxx')}}},[_vm._v("公司介绍")])]),_c('p',[_vm._v("ICP 浙B2-20181300")])]):_vm._e()]),(!_vm.isHxxBaby)?_c('div',{staticClass:"foot-right"},[_c('div',{staticClass:"wx"},[_vm._v("微信公众号")]),_vm._m(6)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/yyzz.png","target":"_blank"}},[_vm._v("营业执照")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"target":"_blank","href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002014018"}},[_vm._v("浙公网安备 33011002014018号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"target":"_blank","href":"https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/cbwjyxkz.png?v=20221123"}},[_vm._v("出版物经营许可证")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"target":"_blank","href":"https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/zzdxywjyxkz.png"}},[_vm._v("增值电信业务经营许可证 浙B2-20181300")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"target":"_blank","href":"https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/wlwhjyxkz.png"}},[_vm._v("网络文化经营许可证（浙网文（2019）2122-225号）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/hxx-yyzz.png","target":"_blank"}},[_vm._v("营业执照")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code"},[_c('img',{attrs:{"src":"https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/wxapp_code.jpg"}})])
}]

export { render, staticRenderFns }