<template>
  <div class="index">
    <!-- <div style="width:100%">
      <div class="indexHeader" :style="indexhead">
        <div class="homeHeader">
          <div class="headerLeft">
            <img class="headerIcon" @click.stop="goIndex" src="https://xcx.alicdn2.hexiaoxiang.com/web_static/logo_m.png"/>
            <div class="navItem" :class="{choose:chooseIndex==1}" @click.stop="goIndex">首页</div>
            <div class="navItem" :class="{choose:chooseIndex==2}"  @click.stop="goPartner">河伙人</div>
            <div class="navItem" :class="{choose:chooseIndex==3}" @click.stop="goJoin">老师入驻</div>
            <div class="navItem" :class="{choose:chooseIndex==4}"  @click.stop="goApp">App下载</div>
          </div>
          <div class="headerRight">
            <div class="telIncon"> </div>
            <div class="tel">400-163-3686</div>
            <button class="headerBtn"  v-if="headImg == null" @click.stop="goLogin">登录</button>
            <img :src="headImg" class="headImg" v-if="headImg != null"  @click.stop="goStudy">
          </div>
        </div>
      </div>
    </div> -->
      <headBar></headBar>
      <div style="width:100%">
        <keep-alive>
          <router-view @changeHeader="changeHeader" @notChangeHeader="notchangeHeader" ></router-view>
        </keep-alive>
        <!-- <div class="homeFoot">
          <foot  @underline="underline"></foot>
        </div> -->
      </div>
          <footBar></footBar>
  </div>

</template>

<script>
import footBar from "@/components/footBar";
import headBar from "@/components/headBar";
export default {
  name: "homeHeader",
  components: { footBar,headBar },
  data() {
    return {
      options: [
        {
          value: "1",
          label: "首页"
        },
        {
          value: "2",
          label: "河伙人"
        },
        {
          value: "3",
          label: "老师入驻"
        },
        {
          value: "4",
          label: "app下载"
        }
      ],
      value: "1",
      indexhead: "",
      chooseIndex: 0,
      headImg: null
    };
  },
  created() {
    if (window.location.pathname == "/") {
      this.chooseIndex = 1;
    } else if (window.location.pathname == "/partner") {
      this.chooseIndex = 2;
    } else if (window.location.pathname == "/teacher") {
      this.chooseIndex = 3;
    } else if (window.location.pathname == "/download") {
      this.chooseIndex = 4;
    } else {
      this.chooseIndex = 0;
    }
  },
  mounted() {
    let key = localStorage.getItem("key");
    let headImg = localStorage.getItem("avatarUrl");
    if (key) {
      this.headImg = headImg || "";
    }
  },
  methods: {
    toHome() {
      this.$router.push({ path: "/m/" });
    },
    underline() {
      this.chooseIndex = 0;
    },
    //跳到视频播放
    goStudy() {
      this.$router.push({ path: "/classroom" });
    },
    changeHeader() {
      this.indexhead =
        "background-color:white; box-shadow:0px 1px 10px 0px rgba(0,0,0,0.16)";
    },
    notchangeHeader() {
      this.indexhead = "background-color:transparent";
    },
    goIndex() {
      this.$router.push({ path: "/" });
      this.indexhead = "background-color:transparent";
      this.chooseIndex = 1;
    },
    goPartner() {
      this.$router.push({ path: "partner" });
      this.chooseIndex = 2;
    },
    goJoin() {
      this.$router.push({ path: "teacher" });
      this.chooseIndex = 3;
    },
    goApp() {
      this.$router.push({ path: "download" });
      this.chooseIndex = 4;
    },
    goLogin() {
      let key = localStorage.getItem("key") || ""; //有key就等于登录了
      if (key != "") {
        this.$router.push({ path: "classroom" });
      } else {
        this.$router.push({ path: "login" });
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
html {
  /* overflow: hidden; */
  /* overflow-y: hidden!important; */
}
.homeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1186px;
  height: 72px;
}
.headerLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.headerRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.headerIcon {
  margin-right: 103px;
  width: 152px;
  height: 44px;
  cursor: pointer;
}
.telIncon {
  margin-right: 11px;
  width: 21px;
  height: 21px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAZlBMVEUAAABRVVdOUVZQVVdQVFhQVFdQVVdRVFdPUlhMTFFRVFdRVVhPU1dQVVVOVFhQVVdRVVdRVFdQVFhQU1dQVFdQVVdQVVhQVVdRVFdQVFhQVFdRU1ZQVFhQUFdQVVdRVVdQVVhRVVgU0oOTAAAAIXRSTlMA+Rr07Nm5TC0H57JAPCXwwqpHN/jeyK6im3AyYRHSeGYF+337AAAA1klEQVQ4y63RWRKCMBBF0YQMAoZRRue3/01qWpAqTOMP9wsqp9KQiH1KyuK0vQ6k8cb6qQGg+D3OtpabooM9eFHkDNDAkYS+hEGDWVRhkGIS0oWBxUeojPsLUMNNMEWSwCjYBgKWBzGomhc9AR2xINIkWl7UoAwvHn9FBSrNp6H9+uaSOyhNx5m30HH4QyGf7xezbLZ0KfDJjF3mn1W2FhpzXaa8WE+J268onRfpWtzKr7Cu8dN+r17N4lhLYAicup1Ff5BVIgJdzSQqR+uB3OiNuYp9egH2yR0BIOg0+gAAAABJRU5ErkJggg==");
  background-size: cover;
}
.navItem {
  margin: 0 20px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
}
.navItem:hover {
  font-weight: 600;
  border-bottom: 2px black solid;
}
.tel {
  font-size: 20px;
  font-family: MicrosoftYaHei-Bold;
  color: rgba(81, 85, 88, 1);
  font-weight: 550;
}
.width80 {
  width: 80px;
}
.headerBtn {
  padding: 0;
  line-height: 30px;
  font-size: 16px;
  color: #515558;
  margin-left: 31px;
  width: 70px;
  height: 30px;
  border: 1px rgba(81, 85, 88, 1) solid;
  border-radius: 15px;
  background: transparent;
  cursor: pointer;
  outline: none;
}
.index {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 1120px;
  position: relative;
}
.indexHeader {
  background-color: transparent;
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: center;
  z-index: 600;
  position: fixed;
  top: 0;
}
.choose {
  margin: 0 20px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  border-bottom: 2px #333 solid;
}
.headImg {
  margin-left: 20px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  background-color: #ddd;
}
.homeFoot {
  width: 100%;
  height: 386px;
  background: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1200px;
}
</style>
